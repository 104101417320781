<template>
    <el-row>
        <!-- 多云管理平台-->
        <fixed-item></fixed-item>
        <div class="cloud-header">
            <div class="header-title1">伊登云·跨平台</div>
            <div class="header-title2">多云管理平台</div>
<!--            <div class="header-btn">-->
<!--                <div class="btn-text">-->
<!--                    在线咨询-->
<!--                    <img src="../../img/product/cloudManage/icon1.svg">-->
<!--                </div>-->
<!--            </div>-->
        </div>

        <div class="cloud-content">
            <div class="content1-img">
                <div>
                    <img src="../../img/product/cloudManage/content_img1.png">
                </div>
                <p class="img1-text">伊登多云管理平台是提供对公有云、私有云以及混合云统一集成管理的平台。其为客户提供资源的管理、服务的监控、以及费用的比对。云管理平台能够把现如今业届主流云厂商的平台兼容，客户可一点操作多个不同云平台的资源，使得企业客户不在受管理方面的困扰。</p>
            </div>
            <div class="content2-module">
                <div class="module-text1">您的企业为什么需要它</div>
                <p class="module-text2">伊登多云管理平台与国内外各大云厂商形成了紧密合作，并完美集成了国内外各大云厂商的云服务，使得企业可以按需在平台中管理及使用世界顶尖的各类云产品，为企业用云提供了更多的选择。</p>
<!--                <div class="module-btn">-->
<!--                    <div class="btn-text">-->
<!--                        在线咨询-->
<!--                        <img src="../../img/product/cloudManage/icon2.svg">-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div class="content3-img">
                <img src="../../img/product/cloudManage/content_img2.png" alt="">
            </div>
            <div class="content4-module">
                <div class="module-text1">轻松解决企业云资源管理难题</div>
                <p class="module-text2">多云管理平台作为云管理的衍生工具，已然成为越来越多公司认可并大力使用，基于伊登多云管理平台的统一管理、精准计算，费用分析、资源管理、监控预警以及自动化运营用等功能，伊登多云管理平台可帮助企业轻松解决云资源管理难题</p>
            </div>

            <div class="content5-list">
                <div class="list-item">
                    <div>
                        <img src="../../img/product/cloudManage/content_img3.png" alt="">
                    </div>
                    <div class="item-right">
                        <div class="right-title">费用分析</div>
                        <div class="right-content">在每季度结算预算时，企业会对上季度经费使用情况的复盘，以便于下个季度更好的使用云。对于这种情况，资源分析采用可视化图表对比分析的方法，不仅为企业提供了各项资费的全部信息，并对其进行数据可视化的处理。</div>
                    </div>
                </div>
                <div class="list-item">

                    <div class="item-left">
                        <div class="left-title">资源管理</div>
                        <div class="left-content">多云之间的资源以API方式一键同步到平台上，实现在同一个平台上对多云资源的 全生命周期管理体系。利用平台AI技术，可深入分析和优化云资源的使用率，帮助企业降低成本。</div>
                    </div>
                    <div>
                        <img src="../../img/product/cloudManage/content_img4.png" alt="">
                    </div>
                </div>
                <div class="list-item">
                    <div>
                        <img src="../../img/product/cloudManage/content_img5.png" alt="">
                    </div>
                    <div class="item-right">
                        <div class="right-title">监控预警</div>
                        <div class="right-content">在每季度结算预算时，企业会对上季度经费使用情况的复盘，以便于下个季度更好的使用云。对于这种情况，资源分析采用可视化图表对比分析的方法，不仅为企业提供了各项资费的全部信息，并对其进行数据可视化的处理。</div>
                    </div>
                </div>
                <div class="list-item">

                    <div class="item-left">
                        <div class="left-title">自动化运营</div>
                        <div class="left-content">在日常运维工作中，涉及到一些需要重复性的工作时，则可以通过任务编排来处理完成，可以将各个作业节点编排成任务，并设置触发条件和作业时间，即可从重复性运维工作中摆脱出来。任务的执行有手动运行、定是运行、重复运行等方式。</div>
                    </div>
                    <div>
                        <img src="../../img/product/cloudManage/content_img6.png" alt="">
                    </div>
                </div>
            </div>

            <div class="content6-service">
                <div class="service-title">完美的售后服务</div>
                <div class="service-items">
                    <div class="service-item">
                        <div class="item-text">本产品由深圳伊登软件有限公司（销售主体）为您优选，并提供服务和发票，由阿里云授权华安视讯科技提供产品技术支持和运维服务</div>
                    </div>
                    <div class="service-item">
                        <div class="item-text">在质保期内若产品制造质量出现问题，享受7日内退货，15日内换货，15日以上在质保期内享受免费保修等三包服务</div>
                    </div>
                    <div class="service-item">
                        <div class="item-text">正常的设备维护保养工作由用户解决，随时提供远程指导</div>
                    </div>
                    <div class="service-item">
                        <div class="item-text">在质保期内，我公司对客户由于使用不当或者人为原因造成材料、设备的损坏，免费保修，只收材料、设备成本费。</div>
                    </div>
                </div>
            </div>

            <div class="content7-footer">
<!--                <div class="footer-try">在线试用</div>-->
            </div>
        </div>
    </el-row>
</template>

<style scoped lang="scss">
* {
    font-size: 14px;
}
.cloud-color {
    color: #3E3D6C;
}
.cloud-header {
    .header-title1 {
        font-size: 48px;
        font-weight: 600;
        color: #3E3D6C;
        text-align: center;
        margin-top: 3%;
    }
    .header-title2 {
        font-size: 30px;
        font-weight: 400;
        color: #3E3D6C;
        text-align: center;
        margin-top: 1.5%;
    }
    .header-btn {
        width: 10%;
        height: 44px;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 22px;
        border: 1px solid #3E3D6C;
        margin: 1.5% auto 0%;
        cursor: pointer;
        .btn-text {
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: #3E3D6C;
        }
    }
}

.cloud-content {
    background: url("../../img/product/cloudManage/bg_1.png") no-repeat top center;
    margin-top: 8%;
    .content1-img {
        text-align: center;
        width: 40%;
        margin: -6% auto 0%;
        .img1-text {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 28px;
        }
    }
    .content2-module {
        width: 44%;
        margin: 8% auto 0%;
        .module-text1 {
            font-size: 40px;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
        }
        .module-text2 {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            margin-top: 5%;
            line-height: 28px;
        }
        .module-btn {
            width: 22%;
            height: 44px;
            display: flex;
            align-items: center;
            border-radius: 22px;
            border: 1px solid #FFFFFF;
            margin: 5% auto 0%;
            cursor: pointer;
            .btn-text {
                width: 100%;
                text-align: center;
                font-weight: 500;
                font-size: 20px;
                color: #FFFFFF;
            }
        }
    }
    .content3-img {
        text-align: center;
        margin-top: 5%;
    }
    .content4-module {
        width: 40%;
        margin: 5% auto 0%;
        .module-text1 {
            font-size: 40px;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
        }
        .module-text2 {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            margin-top: 5%;
            line-height: 28px;
        }
    }
    .content5-list {
        width: 60%;
        margin: auto;
        .list-item {
            margin: 2% 0%;
            display: flex;
            align-items: center;
            .item-right {
                padding: 4% 0% 5% 9%;
                margin-left: 5%;
                background: url('../../img/product/cloudManage/bg_2.png') no-repeat;
                .right-title {
                    font-size: 24px;
                    font-weight: 600;
                    color: #FFFFFF;
                }
                .right-content {
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 28px;
                    margin-top: 3%;
                    width: 63%;
                }
            }
            .item-left {
                padding: 4% 9% 5% 0%;
                margin-right: 5%;
                background: url('../../img/product/cloudManage/bg_3.png') no-repeat right;
                .left-title {
                    font-size: 24px;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: right;
                }
                .left-content {
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 28px;
                    margin-top: 3%;
                    width: 63%;
                    margin-left: 40%;
                }
            }
        }
    }

    .content6-service {
        .service-title {
            text-align: center;
            font-size: 40px;
            font-weight: 500;
            color: #FFFFFF;
        }
        .service-items {
            display: flex;
            flex-wrap: wrap;
            width: 70%;
            margin: 3% auto;
            .service-item {
                padding: 9% 5%;
                margin: 1%;
                width: 38%;
                display: flex;
                align-items: center;
                background: #2B2A4B;
                opacity: 0.8;
                .item-text {
                    font-size: 16px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 28px;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }

    .content7-footer {
        background: url("../../img/product/cloudManage/bg_4.png") no-repeat center;
        height: 225px;
        display: flex;
        align-items: center;
        .footer-try {
            width: 10%;
            height: 20%;
            background: #FFFFFF;
            border-radius: 28px;
            display: flex;
            justify-content:center;
            align-items:center;
            margin: auto;
            cursor: pointer;
        }
    }
}
</style>

<script>
import FixedItem from "../../compoents/fixed/fixedItem";
export default {
    name: "cloudManage",
    components: {FixedItem},
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

